import * as api from '../api';
const GET_WAIVER_FILES = 'waivers_files/GET_WAIVER_FILES';
const initialState = null;

export default function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_WAIVER_FILES:
        return action.data;
      default:
        return state;
    }
  }

export const getWaiverFiles = (jwt, waiverId) => {
    return (dispatch) => {
      return api
        .getWaiverFiles(jwt, waiverId)
        .then((response) => {
          const waivers = response;
          if (waivers) {
            dispatch({ type: GET_WAIVER_FILES, data: waivers });
          } else {
            throw new Error('There was a problem getting waiver files');
          }
        });
    };
  };