import { combineReducers } from 'redux';


import admins from './admins';
import alerts from './alerts';
import dashboard from './dashboard';
import error from './error';
import fares from './fares';
import financialReport from './financialReport';
import fleets from './fleets';
import fleetActiveRides from './fleetActiveRides';
import fleetCoupons from './fleetCoupons';
import fleetFeatures from './fleetFeatures';
import fleetReservations from './fleetReservations';
import fleetRides from './fleetRides';
import fleetUsers from './fleetUsers';
import geofences from './geofences';
import messages from './messages';
import waivers from './waivers';
import waiverFiles from './waiverFiles';
import rides from './rides';
import ride from './ride';
import user from './user';
import vehicles from './vehicles';
import fleetPasskeys from './fleetPasskeys';

const rootReducer = combineReducers({
  admins,
  alerts,
  dashboard,
  error,
  fares,
  financialReport,
  fleets,
  fleetActiveRides,
  fleetCoupons,
  fleetFeatures,
  fleetReservations,
  fleetRides,
  fleetUsers,
  geofences,
  messages,
  waivers,
  waiverFiles,
  rides,
  ride,
  user,
  vehicles,
  fleetPasskeys,
});

export default rootReducer;
